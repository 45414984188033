import SimpleViewPageHeader from "@/App/Layouts/PageHeader/SimpleViewPageHeader";
import ViewLayout, { ViewLayoutVariant } from "@/App/Layouts/ViewLayout";
import InlineUser from "@/App/MainAppView/components/User/InlineUser";
import AuthorizedElement from "@/common/components/Auth/AuthorizedElement";
import AuthorizedMenuItem from "@/common/components/Auth/AuthorizedMenuItem";
import AppIconButton from "@/common/components/Button/AppIconButton";
import SplitDropdownButton from "@/common/components/Button/SplitDropdownButton";
import DataExportModal from "@/common/components/DataExport/DataExportModal";
import DataTabular from "@/common/components/DataTabular/DataTabular";
import Datetime from "@/common/components/Datetime/Datetime";
import EntityChipList from "@/common/components/EntityInfo/EntityChipList";
import EntityPoolsInfoDisplay from "@/common/components/EntityInfo/EntityPoolsInfoDisplay";
import EntityTagsInfoDisplay from "@/common/components/EntityInfo/EntityTagsInfoDisplay";
import InlineApiEnumValue from "@/common/components/Enum/InlineApiEnumValue";
import AppIcon from "@/common/components/Icons/AppIcon";
import MenuWithTrigger from "@/common/components/Menu/MenuWithTrigger";
import TableCellContentOfAttachments from "@/common/components/Table/TableCell/TableCellContentOfAttachments";
import TableCellContentOfCount from "@/common/components/Table/TableCell/TableCellContentOfCount";
import { ROUTE_PATH } from "@/common/constants/routing";
import { FilterCatalog } from "@/common/filters/filterCatalog";
import { EntityHelper } from "@/common/helpers/entity";
import { StringHelper } from "@/common/helpers/string";
import { getTypedPath } from "@/common/helpers/typedPath";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { useCommonRequestParams } from "@/common/hooks/api/useCommonRequestParams";
import { useCurrentTenant } from "@/common/hooks/entity/tenant/useCurrentTenant";
import { useRealtimeDataUpdates } from "@/common/hooks/realtime/useRealtimeDataUpdates";
import { PropagatedDeps } from "@/common/hooks/render/usePropagatedDeps";
import { DataUpdatesChannelName } from "@/common/realtime/dataUpdatesChannelName";
import { ApiEnumName, enumService } from "@/common/services/enum";
import { FilterFieldType } from "@/common/ts/filters";
import { apiClient } from "@/core/api/ApiClient";
import {
  AppPermission,
  DataUpdatesHubClientMethodName,
  EntityType,
  ExportEntityType,
  ImportEntityType,
  PoolItemEntityType,
  TagEntityType,
  VehicleDto,
  VehicleGetPaginatedDto,
} from "@/core/api/generated";
import { Box, IconButton, LinearProgress, Stack, SxProps, Theme, Typography } from "@mui/material";
import { useMemo, useState } from "react";
import { Link as RouterLink, useHistory } from "react-router-dom";
import AssetInline from "../../Asset/AssetInline";
import ContractInline from "../../Contract/ContractInline";
import AffiliationInfoDisplay from "../../EntityAffiliation/AffiliationInfoDisplay";
import GeneralPlateNoDisplay from "../../General/Display/GeneralPlateNoDisplay";
import GeneralAttachedTagsOfEntitiesEditModal from "../../General/GeneralTag/GeneralAttachedTagsOfEntitiesEditModal";
import TenantStructureMembershipOfEntityEditModal from "../../Membership/TenantStructureMembershipOfEntityEditModal";
import PoolItemCreateManyFromEntitiesModal from "../../PoolItem/PoolItemCreateManyFromEntitiesModal";
import VehicleMenu from "../VehicleMenu";

enum BulkActionFlags {
  AddToPool = "AddToPool",
  UpdateTenantStructureMembership = "UpdateTenantStructureMembership",
  UpdateTags = "UpdateTags",
  Export = "Export",
}
const defaultDisplayProps = {
  filters: true,
  create: true,
  edit: true,
  delete: true,
  viewVariant: ViewLayoutVariant.Page,
};

const defaultPropagatedDeps = {
  refetch: {},
};

export type VehiclePaginatedListPropagatedDepsMap = typeof defaultPropagatedDeps;

export interface VehiclePaginatedListOwnProps {
  displayProps?: Partial<typeof defaultDisplayProps>;
  defaultValues?: {
    limit?: number;
    vehicleId?: string;
    customerId?: string;
  };
  propagatedDeps?: PropagatedDeps<VehiclePaginatedListPropagatedDepsMap>;
  sx?: SxProps<Theme>;
}
export type VehiclePaginatedListProps = VehiclePaginatedListOwnProps;

export default function VehiclePaginatedList({
  displayProps,
  defaultValues,
  propagatedDeps,
  sx,
}: VehiclePaginatedListProps) {
  displayProps = {
    ...defaultDisplayProps,
    ...displayProps,
  };

  const history = useHistory();
  const currentTenant = useCurrentTenant();
  const commonRequestParams = useCommonRequestParams<VehicleGetPaginatedDto>({
    statePersistence: {
      persistenceKey: EntityType.Vehicle,
    },
    defaultValues: {
      limit: defaultValues?.limit,
      params: {
        ...defaultValues,
      },
    },
  });

  const [exportVehicleIds, setExportVehicleIds] = useState<string[] | null>(null);
  const [isDataExportModalOpened, setIsDataExportModalOpened] = useState<boolean>(false);

  const paginatedVehiclesRequest = useApiRequest(
    apiClient.vehiclesApi.apiV1VehiclesGetPost,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      vehicleGetPaginatedDto: {
        ...commonRequestParams.params,
        offset: commonRequestParams.offset,
        limit: commonRequestParams.limit,
        search: commonRequestParams.search,
        sortDefinition: commonRequestParams.sortDefinitionDto,
        filterDefinition: commonRequestParams.filterDefinitionDto,
      },
    },
    {
      deps: [...commonRequestParams.deps, propagatedDeps?.depsMap["refetch"]],
      debouncedDeps: {
        deps: [...commonRequestParams.debouncedDeps],
        wait: 500,
        options: { leading: false, trailing: true },
      },
      commonRequestParams: commonRequestParams,
    },
  );

  const paginatedVehicles = useMemo(
    () => paginatedVehiclesRequest?.data,
    [paginatedVehiclesRequest.data],
  );
  const vehicleIds = useMemo(
    () => paginatedVehicles?.items?.map((item) => item.id!) || [],
    [paginatedVehicles?.items],
  );

  const dataUpdatesSub = useRealtimeDataUpdates({
    channelNames: [DataUpdatesChannelName.Entities(currentTenant?.id, EntityType.Vehicle)],
    methodNames: [DataUpdatesHubClientMethodName.EntityChanged],
    handler: undefined,
    entityChangedHandler: (methodName, data) => {
      paginatedVehiclesRequest.handleEntityChanged(data);
    },
  });

  return (
    <Box>
      <ViewLayout
        displayProps={displayProps}
        header={
          <SimpleViewPageHeader
            title={undefined}
            primaryActions={
              displayProps?.create && (
                <AuthorizedElement permissions={[AppPermission.VehicleManage]}>
                  <SplitDropdownButton
                    isSelectAndTriggerAction
                    buttonProps={{
                      variant: "contained",
                      color: "primary",
                      startIcon: <AppIcon of='add' />,
                    }}
                    options={[
                      {
                        content: "Create new vehicle",
                        onClick: () => history.push(ROUTE_PATH.VEHICLE_CREATE),
                      },
                      {
                        content: "Create many similar vehicles",
                        onClick: () => history.push(ROUTE_PATH.VEHICLE_CREATE_MANY_SIMILAR),
                      },
                    ]}
                  />
                </AuthorizedElement>
              )
            }
            secondaryActions={
              <AuthorizedElement
                permissionsAny={[AppPermission.ImportManage, AppPermission.ExportManage]}
              >
                <MenuWithTrigger
                  withAuthCloseOnClick
                  trigger={
                    <IconButton>
                      <AppIcon of='moreVert' />
                    </IconButton>
                  }
                >
                  <AuthorizedMenuItem
                    permissions={[AppPermission.ExportManage]}
                    onClick={() => setIsDataExportModalOpened(true)}
                  >
                    <AppIcon of='export' fontSize='small' sx={{ mr: 1 }} />
                    <Typography>Export vehicles</Typography>
                  </AuthorizedMenuItem>

                  <AuthorizedMenuItem
                    permissions={[AppPermission.ImportManage]}
                    component={RouterLink}
                    to={ROUTE_PATH.MANAGEMENT_IMPORTS_CREATE({
                      entityType: ImportEntityType.Vehicle,
                    })}
                  >
                    <AppIcon of='download' fontSize='small' sx={{ mr: 1 }} />
                    <Typography>Import vehicles</Typography>
                  </AuthorizedMenuItem>
                </MenuWithTrigger>
              </AuthorizedElement>
            }
          />
        }
      >
        <Box
          sx={{
            width: "100%",
            flex: 1,
            backgroundColor: "background.default",
            borderRadius: (th) => `${th.shape.borderRadius}px`,
          }}
        >
          {paginatedVehiclesRequest.isLoading && <LinearProgress sx={{ my: 1 }} />}

          <DataTabular<VehicleDto>
            columns={[
              {
                field: getTypedPath<VehicleDto>().id.$path,
                title: "ID",
                width: 250,
                isVisible: false,
                isHideable: true,
                isSortable: true,
                isFilterable: true,
                renderCell: (item) => item.id,
                filters: {
                  fieldType: FilterFieldType.Id,
                  isUseDefaultOperators: true,
                },
              },
              {
                field: getTypedPath<VehicleDto>().localNumber.$path,
                title: "Number",
                isVisible: false,
                isHideable: true,
                isSortable: true,
                isFilterable: true,
                renderCell: (item) => item.localNumber,
                filters: {
                  fieldType: FilterFieldType.String,
                  isUseDefaultOperators: true,
                },
              },
              {
                field: getTypedPath<VehicleDto>().plateNo.plateNo.$path,
                title: "Plate No",
                width: 200,
                isVisible: true,
                isHideable: true,
                isSortable: true,
                isFilterable: true,
                renderCell: (item) =>
                  item.plateNo ? (
                    <Stack direction='row' spacing={1} sx={{ alignItems: "center" }}>
                      <GeneralPlateNoDisplay
                        plateNo={item.plateNo}
                        withIcon={false}
                        withCountryCode
                        withCountryIcon={false}
                      />
                      <EntityChipList entity={item} variant='compact' />
                    </Stack>
                  ) : (
                    "-"
                  ),
                filters: {
                  fieldType: FilterFieldType.String,
                  isUseDefaultOperators: true,
                },
              },
              {
                field: getTypedPath<VehicleDto>().identificationNumber.$path,
                title: "VIN",
                width: 200,
                isVisible: true,
                isHideable: true,
                isSortable: true,
                isFilterable: true,
                renderCell: (item) => <>{item.identificationNumber || "-"}</>,
                filters: {
                  fieldType: FilterFieldType.String,
                  isUseDefaultOperators: true,
                },
              },
              {
                field: "spec",
                title: "Spec",
                width: 400,
                isVisible: true,
                isHideable: true,
                isSortable: false,
                isFilterable: false,
                renderCell: (item) => (
                  <>
                    {StringHelper.joinIntoString(
                      [
                        item.spec?.make?.name,
                        item.spec?.model?.name,
                        item.spec?.generation?.name,
                        item.spec?.modification?.name,
                        item.spec?.manufactureYear?.toString(),
                        // enumService.getEnumValueName("VehicleType", item.spec?.type),
                        enumService.getEnumValueName("VehicleBodyType", item.spec?.bodyType),
                        enumService.getEnumValueName("VehicleFuelType", item.spec?.fuelType),
                        enumService.getEnumValueName("VehicleGearboxType", item.spec?.gearboxType),
                        enumService.getEnumValueName(
                          "VehicleDrivetrainType",
                          item.spec?.drivetrainType,
                        ),
                      ],
                      ", ",
                      { skipEmpty: true },
                    )}
                  </>
                ),
                // renderCellDetails: (item) => <VehicleSpecDisplay spec={item.spec} columnCount={2} />,
              },
              {
                field: getTypedPath<VehicleDto>().spec.make.id.$path,
                title: "Make",
                isVisible: false,
                isHideable: true,
                isSortable: true,
                isFilterable: true,
                renderCell: (item) => item.spec?.make?.name ?? "-",
                filters: {
                  fieldType: FilterFieldType.Id,
                  operators: FilterCatalog.getOperatorsForIdFieldOfVehicleMake(),
                },
              },
              {
                field: getTypedPath<VehicleDto>().spec.model.id.$path,
                title: "Model",
                isVisible: false,
                isHideable: true,
                isSortable: true,
                isFilterable: true,
                renderCell: (item) => item.spec?.model?.name ?? "-",
                filters: {
                  fieldType: FilterFieldType.Id,
                  operators: FilterCatalog.getOperatorsForIdFieldOfVehicleModel(),
                },
              },
              {
                field: getTypedPath<VehicleDto>().spec.generation.id.$path,
                title: "Generation",
                isVisible: false,
                isHideable: true,
                isSortable: true,
                isFilterable: true,
                renderCell: (item) => item.spec?.generation?.name ?? "-",
                filters: {
                  fieldType: FilterFieldType.Id,
                  operators: FilterCatalog.getOperatorsForIdFieldOfVehicleGeneration(),
                },
              },
              {
                field: getTypedPath<VehicleDto>().spec.modification.id.$path,
                title: "Modification",
                isVisible: false,
                isHideable: true,
                isSortable: true,
                isFilterable: true,
                renderCell: (item) => item.spec?.modification?.name ?? "-",
                filters: {
                  fieldType: FilterFieldType.Id,
                  operators: FilterCatalog.getOperatorsForIdFieldOfVehicleModification(),
                },
              },
              {
                field: getTypedPath<VehicleDto>().spec.bodyType.$path,
                title: "Body type",
                isVisible: false,
                isHideable: true,
                isSortable: true,
                isFilterable: true,
                renderCell: (item) =>
                  item.spec?.bodyType ? (
                    <InlineApiEnumValue type='VehicleBodyType' value={item.spec?.bodyType} />
                  ) : (
                    "-"
                  ),
                filters: {
                  fieldType: FilterFieldType.Enum,
                  isUseDefaultOperators: true,
                  fieldTypeMeta: {
                    enum: {
                      enumName: ApiEnumName.VehicleBodyType,
                    },
                  },
                },
              },
              {
                field: getTypedPath<VehicleDto>().spec.fuelType.$path,
                title: "Fuel type",
                isVisible: false,
                isHideable: true,
                isSortable: true,
                isFilterable: true,
                renderCell: (item) =>
                  item.spec?.bodyType ? (
                    <InlineApiEnumValue type='VehicleFuelType' value={item.spec?.fuelType} />
                  ) : (
                    "-"
                  ),
                filters: {
                  fieldType: FilterFieldType.Enum,
                  isUseDefaultOperators: true,
                  fieldTypeMeta: {
                    enum: {
                      enumName: ApiEnumName.VehicleFuelType,
                    },
                  },
                },
              },
              {
                field: getTypedPath<VehicleDto>().spec.gearboxType.$path,
                title: "Gearbox type",
                isVisible: false,
                isHideable: true,
                isSortable: true,
                isFilterable: true,
                renderCell: (item) =>
                  item.spec?.bodyType ? (
                    <InlineApiEnumValue type='VehicleGearboxType' value={item.spec?.gearboxType} />
                  ) : (
                    "-"
                  ),
                filters: {
                  fieldType: FilterFieldType.Enum,
                  isUseDefaultOperators: true,
                  fieldTypeMeta: {
                    enum: {
                      enumName: ApiEnumName.VehicleGearboxType,
                    },
                  },
                },
              },
              {
                field: getTypedPath<VehicleDto>().spec.drivetrainType.$path,
                title: "Drivetrain type",
                isVisible: false,
                isHideable: true,
                isSortable: true,
                isFilterable: true,
                renderCell: (item) =>
                  item.spec?.bodyType ? (
                    <InlineApiEnumValue
                      type='VehicleDrivetrainType'
                      value={item.spec?.drivetrainType}
                    />
                  ) : (
                    "-"
                  ),
                filters: {
                  fieldType: FilterFieldType.Enum,
                  isUseDefaultOperators: true,
                  fieldTypeMeta: {
                    enum: {
                      enumName: ApiEnumName.VehicleDrivetrainType,
                    },
                  },
                },
              },
              {
                field: getTypedPath<VehicleDto>().spec.manufactureYear.$path,
                title: "Manufacture year",
                isVisible: false,
                isHideable: true,
                isSortable: true,
                isFilterable: true,
                renderCell: (item) => item.spec?.manufactureYear ?? "-",
                filters: {
                  fieldType: FilterFieldType.Number,
                  isUseDefaultOperators: true,
                },
              },
              {
                field: getTypedPath<VehicleDto>().department.name.$path,
                title: "Department",
                isVisible: false,
                isHideable: true,
                isSortable: false,
                isFilterable: false,
                renderCell: (params) => <>{params.department?.name}</>,
              },
              {
                field: getTypedPath<VehicleDto>().lastContract.id.$path,
                title: "Last contract",
                width: 300,
                isVisible: false,
                isHideable: true,
                isSortable: false,
                isFilterable: true,
                renderCell: (item) =>
                  item?.lastContract ? <ContractInline entity={item?.lastContract} /> : "-",
              },
              {
                field: getTypedPath<VehicleDto>().assetMeta.assetId.$path,
                title: "Asset",
                width: 300,
                isVisible: false,
                isHideable: true,
                isSortable: false,
                isFilterable: true,
                renderCell: (item) => (
                  <AssetInline entity={undefined} entityId={item.assetMeta?.assetId} />
                ),
                filters: {
                  fieldType: FilterFieldType.Id,
                  operators: FilterCatalog.getOperatorsForIdFieldOfAsset(),
                },
              },
              {
                field: getTypedPath<VehicleDto>().defaultVisualModel.id.$path,
                title: "Default visual model",
                width: 300,
                isVisible: false,
                isHideable: true,
                isSortable: false,
                isFilterable: true,
                renderCell: (item) => (
                  <AssetInline entity={undefined} entityId={item.assetMeta?.assetId} />
                ),
                filters: {
                  fieldType: FilterFieldType.Id,
                  operators: FilterCatalog.getOperatorsForIdFieldOfVehicleVisualModel(),
                },
              },
              {
                field: getTypedPath<VehicleDto>().mileage.$path,
                title: "Mileage",
                isVisible: false,
                isHideable: true,
                isSortable: true,
                isFilterable: true,
                renderCell: (item) => item.mileage ?? "-",
                filters: {
                  fieldType: FilterFieldType.Number,
                  isUseDefaultOperators: true,
                },
              },
              {
                field: getTypedPath<VehicleDto>().notes.$path,
                title: "Notes",
                width: 100,
                isVisible: false,
                isHideable: true,
                isSortable: false,
                isFilterable: true,
                renderCell: (item) => item.notes || "-",
                filters: {
                  fieldType: FilterFieldType.String,
                  isUseDefaultOperators: true,
                },
              },
              {
                field: getTypedPath<VehicleDto>().totalDamagesCount.$path,
                title: "Total damages #",
                isVisible: false,
                isHideable: true,
                isSortable: true,
                isFilterable: true,
                renderCell: (item) => item.totalDamagesCount ?? "-",
                filters: {
                  fieldType: FilterFieldType.Number,
                  isUseDefaultOperators: true,
                },
              },
              {
                field: getTypedPath<VehicleDto>().actualDamagesCount.$path,
                title: "Actual damages #",
                isVisible: false,
                isHideable: true,
                isSortable: true,
                isFilterable: true,
                renderCell: (item) => item.actualDamagesCount ?? "-",
                filters: {
                  fieldType: FilterFieldType.Number,
                  isUseDefaultOperators: true,
                },
              },
              {
                field: getTypedPath<VehicleDto>().repairedDamagesCount.$path,
                title: "Repaired damages #",
                isVisible: false,
                isHideable: true,
                isSortable: true,
                isFilterable: true,
                renderCell: (item) => item.repairedDamagesCount ?? "-",
                filters: {
                  fieldType: FilterFieldType.Number,
                  isUseDefaultOperators: true,
                },
              },
              {
                field: getTypedPath<VehicleDto>().lastVisualInspectionAt.$path,
                title: "Last visual inspection at",
                isVisible: false,
                isHideable: true,
                isSortable: true,
                isFilterable: true,
                renderCell: (item) =>
                  item.lastVisualInspectionAt ? (
                    <Datetime datetime={item.lastVisualInspectionAt} withDurationFromNow />
                  ) : (
                    "-"
                  ),
                filters: {
                  fieldType: FilterFieldType.Date,
                  isUseDefaultOperators: true,
                },
              },
              {
                field: getTypedPath<VehicleDto>().lastDamageDetectionAt.$path,
                title: "Last damage detection at",
                isVisible: false,
                isHideable: true,
                isSortable: true,
                isFilterable: true,
                renderCell: (item) =>
                  item.lastDamageDetectionAt ? (
                    <Datetime datetime={item.lastDamageDetectionAt} withDurationFromNow />
                  ) : (
                    "-"
                  ),
                filters: {
                  fieldType: FilterFieldType.Date,
                  isUseDefaultOperators: true,
                },
              },
              {
                field: getTypedPath<VehicleDto>().lastAccessoryCheckAt.$path,
                title: "Last accessory check at",
                isVisible: false,
                isHideable: true,
                isSortable: true,
                isFilterable: true,
                renderCell: (item) =>
                  item.lastAccessoryCheckAt ? (
                    <Datetime datetime={item.lastAccessoryCheckAt} withDurationFromNow />
                  ) : (
                    "-"
                  ),
                filters: {
                  fieldType: FilterFieldType.Date,
                  isUseDefaultOperators: true,
                },
              },
              {
                field: getTypedPath<VehicleDto>().lastMileageUpdatedAt.$path,
                title: "Last mileage update at",
                isVisible: false,
                isHideable: true,
                isSortable: true,
                isFilterable: true,
                renderCell: (item) =>
                  item.lastMileageUpdatedAt ? (
                    <Datetime datetime={item.lastMileageUpdatedAt} withDurationFromNow />
                  ) : (
                    "-"
                  ),
                filters: {
                  fieldType: FilterFieldType.Date,
                  isUseDefaultOperators: true,
                },
              },
              {
                field: getTypedPath<VehicleDto>().createdAt.$path,
                title: "Created at",
                isVisible: false,
                isHideable: true,
                isSortable: true,
                isFilterable: true,
                renderCell: (item) =>
                  item.createdAt ? <Datetime datetime={item.createdAt} withDurationFromNow /> : "-",
                filters: {
                  fieldType: FilterFieldType.Date,
                  isUseDefaultOperators: true,
                },
              },
              {
                field: getTypedPath<VehicleDto>().updatedAt.$path,
                title: "Updated at",
                isVisible: false,
                isHideable: true,
                isSortable: true,
                isFilterable: true,
                renderCell: (item) =>
                  item.updatedAt ? <Datetime datetime={item.updatedAt} withDurationFromNow /> : "-",
                filters: {
                  fieldType: FilterFieldType.Date,
                  isUseDefaultOperators: true,
                },
              },
              {
                field: getTypedPath<VehicleDto>().createdBy.$path,
                title: "Created by",
                isVisible: false,
                isHideable: true,
                isSortable: false,
                isFilterable: true,
                renderCell: (item) =>
                  item.createdBy ? <InlineUser userId={item.createdBy} withAvatar /> : "-",
                filters: {
                  fieldType: FilterFieldType.Id,
                  operators: FilterCatalog.getOperatorsForIdFieldOfUser(),
                },
              },
              {
                field: getTypedPath<VehicleDto>().updatedBy.$path,
                title: "Updated by",
                isVisible: false,
                isHideable: true,
                isSortable: false,
                isFilterable: true,
                renderCell: (item) =>
                  item.updatedBy ? <InlineUser userId={item.updatedBy} withAvatar /> : "-",
                filters: {
                  fieldType: FilterFieldType.Id,
                  operators: FilterCatalog.getOperatorsForIdFieldOfUser(),
                },
              },
              {
                field: getTypedPath<VehicleDto>().accessories.$path,
                title: "Accessories",
                isVisible: false,
                isHideable: true,
                isSortable: false,
                isFilterable: false,
                renderCell: (item) => <TableCellContentOfCount count={item.accessories?.length} />,
              },
              {
                field: getTypedPath<VehicleDto>().attachments.$path,
                title: "Attachments",
                isVisible: false,
                isHideable: true,
                isSortable: false,
                isFilterable: false,
                renderCell: (item) => (
                  <TableCellContentOfAttachments attachments={item.attachments} />
                ),
              },
              {
                field: getTypedPath<VehicleDto>().poolsMeta.$path,
                title: "Pools",
                isVisible: true,
                isHideable: true,
                isSortable: false,
                isFilterable: false,
                renderCell: (item) => (
                  <EntityPoolsInfoDisplay
                    entityType={PoolItemEntityType.Vehicle}
                    entity={item}
                    isIncludeAssetPools
                    noDataPlaceholder='-'
                  />
                ),
              },
              {
                field: "affiliation",
                title: "Affiliation",
                description: "Affiliation to departments & locations",
                width: 120,
                isVisible: true,
                isHideable: true,
                isSortable: false,
                isFilterable: false,
                isToDisabled: true,
                renderCell: (item) => (
                  <AffiliationInfoDisplay
                    variant='compact'
                    tenantId={item.tenantId}
                    departmentIds={item.departmentIds}
                    locationIds={item.locationIds}
                  />
                ),
              },
              {
                field: getTypedPath<VehicleDto>().tags.$path,
                title: "Tags",
                isVisible: true,
                isHideable: true,
                isSortable: false,
                isFilterable: false,
                isToDisabled: true,
                renderCell: (item) => (
                  <EntityTagsInfoDisplay
                    entityType={TagEntityType.Vehicle}
                    entity={item}
                    noDataPlaceholder='-'
                    edit={{
                      onSaved: (newValue) =>
                        paginatedVehiclesRequest.updateData((data) => {
                          data.items?.forEach((item2) => {
                            if (item2.id === item.id) {
                              item2.tags = newValue || undefined;
                            }
                          });
                        }),
                    }}
                  />
                ),
              },
            ]}
            rows={paginatedVehicles?.items || []}
            rowTo={(item) => ROUTE_PATH.VEHICLE_VIEW(item.id)}
            getRowId={(item) => item.id || ""}
            renderRowAction={({ item }) => {
              return (
                <VehicleMenu
                  entity={item}
                  onDelete={() => paginatedVehiclesRequest.refetch()}
                  onUpdate={() => paginatedVehiclesRequest.refetch()}
                  displayProps={{
                    actions: {
                      edit: true,
                      createContract: true,
                      createAsset: true,
                      addToPool: true,
                      createVisualInspection: false,
                      createDamageDetection: false,
                      createDamageCostEvaluation: false,
                      createRepairOperation: false,
                      createAccessoryCheck: false,
                    },
                  }}
                />
              );
            }}
            statePersistence={commonRequestParams.dataTabularProps.statePersistence}
            pagination={commonRequestParams.dataTabularProps.pagination}
            sort={commonRequestParams.dataTabularProps.sort}
            quickFilter={commonRequestParams.dataTabularProps.quickFilter}
            filters={commonRequestParams.dataTabularProps.filters}
            bulkActions={{
              enabled: true,
              definition: BulkActionFlags,
              actionTriggers: ({ currentAction, startAction, selectedIds }) => (
                <>
                  <AppIconButton
                    tooltipProps={{ title: "Add to pool" }}
                    onClick={() => startAction(BulkActionFlags.AddToPool)}
                  >
                    <AppIcon of='pool' />
                  </AppIconButton>
                  <AppIconButton
                    tooltipProps={{ title: "Update affiliation" }}
                    onClick={() => startAction(BulkActionFlags.UpdateTenantStructureMembership)}
                  >
                    <AppIcon of='department' />
                  </AppIconButton>
                  <AppIconButton
                    tooltipProps={{ title: "Update tags" }}
                    onClick={() => startAction(BulkActionFlags.UpdateTags)}
                  >
                    <AppIcon of='tag' />
                  </AppIconButton>
                  <AppIconButton
                    tooltipProps={{ title: "Export" }}
                    onClick={() => startAction(BulkActionFlags.Export)}
                  >
                    <AppIcon of='export' />
                  </AppIconButton>
                </>
              ),
              actionHandlers: ({ selectedIds, currentAction, cancelAction, completeAction }) => {
                const selectedEntities = EntityHelper.filterEntitiesByIds(
                  paginatedVehicles?.items || [],
                  selectedIds as string[],
                );
                return (
                  <>
                    <PoolItemCreateManyFromEntitiesModal
                      open={currentAction === BulkActionFlags.AddToPool}
                      onClose={() => cancelAction()}
                      createProps={{
                        entityType: PoolItemEntityType.Vehicle,
                        entities: selectedEntities,
                        onSave: (newValue) => {
                          completeAction();
                          paginatedVehiclesRequest.refetch();
                        },
                      }}
                    />
                    <TenantStructureMembershipOfEntityEditModal
                      open={currentAction === BulkActionFlags.UpdateTenantStructureMembership}
                      onClose={() => cancelAction()}
                      tenantStructureMembershipOfEntityEditProps={{
                        entityType: EntityType.Vehicle,
                        entities: selectedEntities,
                        onSave: () => {
                          completeAction();
                          paginatedVehiclesRequest.refetch();
                        },
                      }}
                    />
                    <GeneralAttachedTagsOfEntitiesEditModal
                      tagEntityType={TagEntityType.Vehicle}
                      entities={selectedEntities}
                      open={currentAction === BulkActionFlags.UpdateTags}
                      onClose={() => cancelAction()}
                      onSaved={() => {
                        completeAction();
                        paginatedVehiclesRequest.refetch();
                      }}
                    />
                    <DataExportModal
                      open={currentAction === BulkActionFlags.Export}
                      onClose={() => cancelAction()}
                      exportDataProps={{
                        entityType: ExportEntityType.Vehicle,
                        ids: selectedIds as string[],
                        onExportDone: () => completeAction(),
                        restrictSelectExportMode: true,
                      }}
                    />
                  </>
                );
              },
            }}
          />
        </Box>

        {/* Export data modal */}
        <DataExportModal
          open={isDataExportModalOpened}
          onClose={() => {
            setIsDataExportModalOpened(false);
          }}
          exportDataProps={{
            entityType: ExportEntityType.Vehicle,
            ids: vehicleIds,
            onExportDone: () => {
              setIsDataExportModalOpened(false);
            },
          }}
        />
      </ViewLayout>
    </Box>
  );
}
